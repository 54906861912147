body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  /* Light theme variables - update text colors */
  --bg-color: #ffffff;
  --text-color: #333333;
  --sidebar-bg: #ffffff;
  --chat-bg: #ffffff;
  --border-color: lightgray;
  --input-bg: #ffffff;
  --input-holder-bg: #ffffff;
  --message-bg: #f7f7f8;
  --bot-message-bg: #f0f7f0;
  --menu-hover: #f5f5f5;
  --mobile-nav-bg: #ffffff;
  --icon-color: #333333;
  --menu-text: #333333;
  --send-button-color: #333333;
  --input-text-color: #333333;
  --chat-text: #333333;
  --message-text: #333333;
  --chat-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  --sidebar-border: 1px solid #e5e5e5;
}

[data-theme='dark'] {
  /* Dark theme variables */
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --sidebar-bg: #202123;
  --chat-bg: #282c34;
  --border-color: #404040;
  --input-bg: #40414f;
  --input-holder-bg: #282c34;
  --message-bg: #2d2d2d;
  --bot-message-bg: #383838;
  --menu-hover: #343638;
  --mobile-nav-bg: #202123;
  --icon-color: #ffffff;
  --menu-text: #ffffff;
  --send-button-color: #ffffff;
  --input-text-color: #ffffff;
  --chat-text: #ffffff;
  --message-text: #ffffff;
  --chat-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  --sidebar-border: 1px solid #404040;
}

/* Update component styles to use variables */
.main-sec {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.chat-container {
  text-align: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sidebar,
.mobile-sidebar {
  width: 245px;
  background-color: var(--sidebar-bg);
  padding: 10px;
  position: relative;
  border-right: var(--sidebar-border);
  color: var(--menu-text);
}

.chat-box {
  flex: 1;
  position: relative;
  background-color: var(--chat-bg);
}

.mobile-nav {
  background-color: var(--mobile-nav-bg);
  border-bottom: var(--sidebar-border);
}

.line-1,
.line-2,
.line-3 {
  background-color: var(--icon-color);
}

.chat-input-textarea {
  background-color: var(--input-bg);
  width: 100%;
  border: 1px solid var(--border-color);
  outline: none;
  padding: 10px 60px 10px 25px;
  color: var(--input-text-color);
  font-size: 16px;
  box-shadow: var(--chat-shadow);
  border-radius: 50px;
  resize: none;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;
  line-height: 1.5;
}

.user-message {
  justify-content: flex-end;
  margin: 8px 0;
  width: 100%;
  gap: 12px;
  flex-direction: row;
}

.user-message .message {
  background-color: #0084ff;
  color: white !important;
  margin-right: 12px;
  border-bottom-right-radius: 4px;
  margin-left: 0;
  order: 1;
}

.user-message .avatar {
  order: 2;
}

.bot-message .message {
  background-color: var(--bot-message-bg);
  color: var(--message-text) !important;
  box-shadow: var(--chat-shadow);
}

.chat-tab:hover {
  background-color: var(--menu-hover);
}

.menu-btn-bar {
  color: var(--menu-text);
}

.menu-btn-bar:hover {
  color: var(--menu-text);
  background-color: var(--menu-hover);
}

.theme-toggle button {
  color: var(--icon-color);
}

.sidebar-menu-btn {
  background-color: var(--sidebar-bg);
}

/* Update existing styles */
.side-menu-btn {
  border: 1px solid var(--border-color);
  color: var(--menu-text);
}

.side-menu-btn:hover {
  background-color: var(--menu-hover);
}

.chat-container {
  text-align: center;
  background-color: var(--chat-bg);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sidebar {
  width: 245px;
  background-color: var(--sidebar-bg);
  padding: 10px;
  position: relative;
}

.chat-box {
  flex: 1;
  position: relative;
  background-color: var(--chat-bg);
}

.side-menu-btn {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 15px;
}

.side-menu-btn:hover {
  background-color: #e5e5e5;
}

.side-menu-btn span {
  padding-left: 6px;
  padding-right: 12px;
  font-size: 20px;
  line-height: 20px;
}

.chat-input-holder {
  padding: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--input-holder-bg);
}

.chat-input-holder.top {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: auto;
  z-index: 10;
  background-color: var(--input-holder-bg);
  max-height: 60px;
}

.main-text-box {
  position: relative;
}

.main-search-box {
  max-width: 850px;
  margin: 0 auto;
}

.centeral-logo {
  max-width: 120px;
  border-radius: 3px;
}

.centeral-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 3px;
}

.chat-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 850px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--chat-bg);
  padding: 0 15px;
}
.main-chat-container{
  width: 850px;
  margin: 0 auto;
}
.chat-nav span{
  color: var(--send-button-color);
  cursor: pointer;
}
.chat-nav a {
  color: white
}

.chat-input-textarea {
  background-color: var(--input-bg);
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  outline: none;
  padding: 10px 60px 10px 25px;
  color: var(--input-text-color);
  font-size: 16px;
  box-shadow: var(--chat-shadow);
  border-radius: 50px;
  resize: none;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;
  line-height: 1.5;
}

.chat-input-textarea::-webkit-scrollbar {
  display: none;
}

.chat-log {
  padding: 5px 0;
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chat-message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto !important;
  max-width: 1000px;
}

.user-message {
  justify-content: flex-end;
  margin: 0px 0 0px auto;
  width: 100%;
  gap: 12px;
  flex-direction: row;
  /* Ensure correct direction */
}

.user-message .message {
  background-color: #0084ff;
  color: white !important;
  border-bottom-right-radius: 4px;
  margin-left: 0;
  order: 1;
  margin-right: 0 !important;
}

.user-message .avatar {
  order: 2;
  /* Move avatar to the end */
}

.bot-message {
  justify-content: flex-start;
  margin: 0 auto 0 0;
  width: 100%;
  gap: 12px;
}

.bot-message .message {
  background-color: var(--bot-message-bg);
  border-bottom-left-radius: 4px;
}

.user-message .message {
  background-color: #0084ff;
  color: white !important;
  margin-left: auto;
  border-bottom-right-radius: 4px;
}

.avatar {
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-history {
  padding: 0 0 7px 0;
  height: calc(81vh - 30px);
  overflow-y: auto;
}

.chat-history::-webkit-scrollbar {
  display: none;
}

.chat-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 5px;
}

.chat-tab:hover {
  background-color: #e5e5e5;
}

.chat-tab p {
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  padding: 0 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--menu-text);
}

.action-icons {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message {
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 15px;
  line-height: 1.5;
  width: 100%;
  color: var(--message-text) !important;
  font-weight: 400;
  letter-spacing: 0.2px;
  min-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-messsage.cstast {
  background-color: #40414f;
}

.send-message {
  position: absolute;
  top: 53%;
  right: 0%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: var(--send-button-color);
  padding: 11px 7px;
}

.main-chat-container {
  padding-top: 55px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column; 
  overflow-y: auto;
  scroll-behavior: smooth;
}

.main-chat-container::-webkit-scrollbar {
  display: none;
}

.sidebar-menu-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--sidebar-bg);
  padding: 7px 0;
}

.menu-btn-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--menu-text);
  text-decoration: none;
}

.menu-btn-bar:hover {
  color: var(--menu-text);
  text-decoration: none;
}

.menu-btn-bar span {
  padding-right: 10px;
}

.menu-btn-bar p {
  margin: 0;
  font-size: 15px;
}

/* loader animation start here */

.loader {
  width: 35px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000000 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}



/* loader animation eds here */

/* welcome page css start here */

/* desktop header css start here */
.main-container {
  position: relative;
  height: 100%;
}

.desktop-header {
  background-color: transparent;
  padding: 10px 0;
  position: absolute;
  top: 0;
  z-index: 5000;
  width: 100%;
  height: 100px;
}

.brand-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.custom-header-width {
  padding: 0 110px 0 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-img {
  height: 77px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 140px;
}

.custom-nav-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom-nav-list ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
}

.custom-nav-list ul li {
  padding: 10px 16px;
  margin: 0px 1px;
  list-style: none;
  font-family: 'Lato';
  letter-spacing: 1px;
}

.custom-nav-list ul li a {
  text-decoration: none;
  color: black;
  line-height: 16px;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  font-family: "Roboto", serif;
}

.custom-nav-list ul li a:hover {
  color: black;
}

.custom-nav-list ul li span.fa {
  color: black;
  cursor: pointer;
}

.static-position {
  position: static !important;
}

.custom-nav-list ul li span.fa:hover {
  color: #01037B;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn {
  padding: 7px 33px;
  border-radius: 50px;
  background-color: rgb(10 203 139);
  color: white;
  text-decoration: none;
  transition: all 0.5s;
}

.login-btn:hover {
  color: white !important;
  background-color: rgb(11 179 123);
  ;
  text-decoration: none;
}

/* mobile header start here */

.mobile-header {
  display: none;
}

.mobile-header-list {
  display: none;
  position: absolute;
  top: 90px;
  left: 0;
  z-index: 200;
  width: 100%;
  background: white;
}

.mobile-header-list ul {
  padding: 0;
}

.mobile-header-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  width: 100%;
  height: 90px;
  background: white;
  z-index: 500;
}

.mobile-header-img {
  width: 150px;
  height: auto;
}

.mobile-header-img img {
  width: 100%;
  object-fit:
    contain;
}

.custom-toggle-icon span.fa {
  font-size: 30px;
  background-color: #241C1C;
  color: black;
}

.custom-nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  color: black;
  text-decoration: none;
  letter-spacing: 1px;
  font-family: "Roboto", serif;
}

.custom-nav-link:hover {
  text-decoration: none;
  color: black;
}

.mobile-header-list ul li {
  list-style: none;
  border-bottom: 2px solid lightgray;
}

.submenu-nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 45px;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Roboto", serif;
}

.sub-menu {
  display: none;
}

.nav-search {
  border: none !important;
}

.nav-search:focus {
  box-shadow: none;
  outline: none;
}

.mobile-header-number {
  text-decoration: none;
  color: black;
}

.mobile-header-number:hover {
  text-decoration: none;
  color: black;
}

/* mobile header end here */


/* welcome page style strat here */

.welcome-banner {
  background-image: url("./Assets/Images/header-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.banner-content {
  padding: 150px 20px;
  min-height: 100vh;
}

.banner-content h3 {
  font-size: 72px;
  text-align: center;
  letter-spacing: 0px;
  line-height: normal;
  font-weight: 800;
  background: linear-gradient(to right, #00BF63, #000000E5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.banner-content ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-list-item {
  position: relative;
  padding: 0 20px;
  font-size: 20px;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0px;
  list-style: none;
}

.banner-list-item+.banner-list-item::after {
  position: absolute;
  content: "";
  top: 50%;
  left: -3px;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50px;
  transform: translateY(-50%);
}

.banner-content p {
  font-size: 18px;
  color: black;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0px;
}

.banner-btn {
  color: white;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: normal;
  display: inline-block;
  padding: 10px 55px;
  background-color: rgb(10 203 139);
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.5s;
}

.banner-btn:hover {
  color: white;
  background-color: rgb(11 179 123);
  ;
}

.featured-sec {
  position: relative;
  margin-top: -120px;
  margin-bottom: 120px;
}

.featured-card-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
  padding: 0 100px;
}

.featured-card-list .featured-card {
  width: calc(25% - 30px);
  border: 5px solid white;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.06);
  transition: all 0.5s;
  cursor: pointer;
}

.featured-card-list .featured-card:hover {
  transform: translateY(-10px);
}

.featured-card-img {
  position: relative;
  padding-top: 60%;
}

.featured-card-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-card-title {
  padding: 15px 10px;
}

.featured-card-title h3 {
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  line-height: normal;
}

.sports-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}

.sports-card {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  transition: all 0.5s;
  padding-top: 16px;
}

.sports-card:hover {
  transform: translateY(-10px);
}

.sports-card h4 {
  font-size: 17px;
  color: black;
  font-weight: 500;
  list-style: none;
  margin-bottom: 0;
}

.sports-card span {
  display: block;
  font-size: 14px;
  color: black;
  font-weight: 400;
  text-align: center;
}

.sports-card-1 {

  background: #00BF6357;
}

.sports-card-2 {
  background: #95A49D45;
}

.sports-card-3 {
  background: #95A49D45;
}

.card-icon {
  width: 50px;
  height: 50px;
}

.card-icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.sports-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.sports-list ul li {
  list-style: none;
  background-color: rgb(10 203 139);
  border-radius: 50px;
}

.sports-list ul li a {
  padding: 10px 25px;
  display: inline-block;
  color: white;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 500;
}

.sports-list ul li a:hover {
  color: white;
  text-decoration: none;
}

/* footer css start here */

.footer-img {
  width: 200px;
  height: auto;
}

.footer-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-menu ul li span {
  font-size: 19px !important;
  margin-right: 9px;
}

.footer-menu ul li {
  list-style: none;
  line-height: 40px;
}

.footer-menu ul li a {
  text-decoration: none;
  font-family: "Roboto", serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.footer-menu ul li a:hover {
  text-decoration: none;
  color: black;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: black;
  font-family: 'Lato-Bold';
}

.footer-links:hover {
  text-decoration: none;
  color: black;
}

.quick-links ul {
  padding-left: 0;
}

.quick-links li {
  list-style: none;
}

.quick-links li a {
  font-family: "Roboto", serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.mail-box-sec {
  font-family: "Roboto", serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.footer-content-menu {
  display: none;
}

.footer-menu-link {
  text-decoration: none;
  color: black;
}

.footer-menu-link:hover {
  text-decoration: none;
  color: black;
}

.footer-menu-show {
  display: block !important;
}

footer h5 {
  margin-bottom: 20px;
  font-family: "Roboto", serif;
  letter-spacing: 1.5px;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact-sec p {
  font-family: "Roboto", serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.contact-sec .footer-number {
  text-decoration: none;
  color: black;
}

.contact-sec .footer-number:hover {
  text-decoration: none;
  color: black;
}

.social-media-icons a {
  text-decoration: none;
  margin-right: 15px;
  font-size: 20px;
  background: #00375A;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  color: white;
}

.social-media-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-end-parent {
  background-color: #F8F8F8;
}

.footer-end {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 0;
}

.footer-end p {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.footer-end p a {
  text-decoration: underline;
  color: black;
  text-transform: uppercase;
}

.footer-end p a:hover {
  text-decoration: underline;
  color: black;
}

.footer-top-sec {
  padding: 100px 0;
  border-top: 1px solid #f0f1f1;
  background: #f0f1f1;
}

.contact-sec {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact-col-1,
.contact-col-2 {
  width: 50%;
}

.mail-box {
  display: flex;
}

.mail-box button {
  padding: 7px 20px;
  background-color: black;
  border: 2px solid black;
  color: white;
  outline: none;
}

.mail-box button:focus {
  outline: none;
}

.mail-box input {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border-radius: none;
  border: 2px solid black;
}

.mail-box input:focus {
  border-radius: none;
  outline: none;
}

footer {
  padding: 30px 110px;
  background-color: black;
}

.c-list {
  display: flex;
  gap: 10px;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.c-list span {
  color: white;
}

.c-list a {
  color: white;
}

.c-list a:hover {
  color: white;
}

.footer-policy h4 {
  font-size: 16px;
  color: black;
  font-weight: 500;
  list-style: none;
}

.footer-policy a {
  color: white;
}

.footer-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 0 auto !important;
  gap: 30px;
}

.footer-contact a {
  color: white;
}

.pt-100 {
  padding-top: 100px;
}

/* chhat-page-nav start here */

.nav-icon {
  display: flex;
  justify-items: center;
  align-items: flex-start;
  flex-direction: column;
  width: 40px;
  gap: 5px;
}

.line-1 {
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: var(--icon-color);
}

.line-2 {
  width: 70%;
  height: 5px;
  border-radius: 3px;
  background-color: var(--icon-color);
}

.line-3 {
  width: 50%;
  height: 5px;
  border-radius: 3px;
  background-color: var(--icon-color);
}

.mobile-sidebar {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 99;
  width: 245px;
  height: calc(100% - 75px);
  background-color: var(--sidebar-bg);
  padding: 10px;
  transition: all 0.3s ease;
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.mobile-sidebar.hidden {
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
}

.mobile-nav {
  display: none;
  background-color: var(--mobile-nav-bg);
}

/* type animation */

@keyframes typing {
  from {
    width: 0;
    opacity: 1;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 4s steps(20, end) forwards;
  width: 0;
  color: var(--menu-text);
}

.initial-chat-input .main-search-box.initial {
  margin-top: 35vh;
  padding: 0 15px;
}

@media screen and (max-width: 1500px) {

  .banner-content h3 {
    font-size: 60px;
  }

  .featured-card-list {
    gap: 35px;
  }
}

@media screen and (max-width: 1400px) {
  .sports-list {
    gap: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .chat-log {
    padding: 5px;
    max-width: 70%;
    margin: 0 auto;
  }

  .chat-nav {
    max-width: 70% !important;
  }
  .main-chat-container{
    max-width: 70% !important;
  }

  .main-search-box {
    max-width: 70% !important;
  }

  .featured-card-list {
    padding: 0 50px;
  }

  .banner-content h3 {
    font-size: 50px;
  }

  .featured-card-list {
    padding: 0 50px;
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 1200px) {
  .welcome-banner {
    position: relative;
    height: unset;
  }

  .sports-list {
    gap: 15px;
    flex-wrap: wrap;
    padding: 0 0px;
  }

  .featured-card-list .featured-card {
    width: calc(50% - 30px);
  }

  .custom-header-width {
    padding: 0 50px 0 50px;
  }

  .banner-content h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: 991px) {
  .mobile-header-list {
    display: block;
  }

  footer {
    padding: 60px 60px;
  }

  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: block;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
  }

  .custom-toggle-icon span.fa {
    color: rgb(10 203 139);
    font-size: 30px;
    background-color: transparent;
  }

  .featured-sec {
    margin-top: -90px;
  }

  .featured-card-list {
    padding: 0 20px;
  }

  footer h5 {
    text-align: center;
  }

  .quick-links li {
    text-align: center;
  }

  .contact-sec p {
    text-align: center;
  }

  .mail-box-sec {
    text-align: center;
    margin-bottom: 25px;
  }

  .footer-menu ul li {
    text-align: center;
  }

  .footer-top-sec {
    padding: 50px 0;
  }

  .mail-box {
    display: flex;
    max-width: 500px;
    margin: 0 auto;
  }

  .contact-sec {
    margin-bottom: 50px;
  }

  .quick-links ul {
    margin-bottom: 25px;
  }

  .mobile-sidebar {
    display: block;
    /* Ensure sidebar is displayed on mobile */
    position: fixed;
    /* Change to fixed positioning */
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    z-index: 1000;
  }
}

@media screen and (max-width: 767px) {
  .send-message {
    top: 45%;
  }
  .chat-input-holder.top {
    top: 63px;
  }

  .chat-input-holder {
    max-width: 100%;
    padding: 0 12px;
  }

  .mobile-nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 10px 15px;
    background-color: var(--mobile-nav-bg);
  }

  .chat-input-textarea {
    margin: 10px 10px 12px 0;
  }

  .sidebar {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 99;
  }

  .mobile-chat-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .mobile-chat-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .contact-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }

  .contact-info {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }

  .footer-policy {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;
  }

  .featured-card-list {
    gap: 15px;
  }

  .featured-card-list .featured-card {
    width: 100%;
  }

  .featured-sec {
    margin-bottom: 0px;
  }

  .banner-content h3 {
    font-size: 38px;
  }

  .banner-list-item {
    font-size: 18px;
  }

  .banner-content p {
    font-size: 16px;
  }

  .chat-message {
    padding: 0px 0px;
  }

  .chat-input-holder.top {
    left: 0;
    /* Full width on mobile */
  }

}

@media screen and (max-width: 576px) {
  .main-search-box {
    max-width: 95% !important;
  }

  .chat-nav {
    max-width: 95% !important;
  }
  .main-chat-container{
    max-width: 95% !important;
  }

  .chat-log {
    padding: 5px;
    max-width: 95%;
    margin: 0 auto;
  }

  .message {
    margin-left: 10px;
    margin-right: 10px;
  }
  .user-message .message {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .banner-content {
    padding: 135px 20px 50px 20px;
  }

  .welcome-banner-img {
    width: 100%;
    height: 100%;
  }

  .sports-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
    margin-top: 50px;
  }

  .contact-sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .banner-content ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
  }

  .banner-list-item {
    font-size: 16px;
  }

  .banner-content h3 {
    font-size: 33px;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 430px) {
  footer {
    padding: 60px 20px;
  }
}

@media screen and (max-width: 350px) {
  .sports-list {
    flex-wrap: unset;
    flex-direction: column;
  }

}

[data-theme='dark'] .bot-message .message {
  background-color: var(--bot-message-bg);
  color: #ffffff !important;
  border-bottom-left-radius: 4px;
}

[data-theme='dark'] .chat-tab p {
  color: #ffffff;
}

[data-theme='dark'] .side-menu-btn:hover {
  background-color: #404040;
  color: white;
}

[data-theme='dark'] .chat-tab:hover {
  background-color: #343638;
}

/* Add styles for better text contrast in dark mode */
[data-theme='dark'] .chat-input-textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

[data-theme='dark'] .loader {
  width: 35px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #fffcfc 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}